import React from "react"
import "../styles/styles.scss"

import Header from "../components/Header"
import Banner from "../components/Banner"
import AboutBlurb from "../components/AboutBlurb"

const IndexPage = () => (
  <div>
    <Header />
    <Banner />
    <AboutBlurb />
  </div>
)

export default IndexPage
