import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const AboutBlurb = () => {
  const data = useStaticQuery(graphql`
    query {
      flower: file(relativePath: {eq: "flower-mouth.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fist: file(relativePath: {eq: "fist.png"}) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }  
  `)
  return (
    <div className='about-blurb'>
      <div className="container">
        <div className="inner-blurb">
          <div className="content">
            <h3>The Ability To Create</h3>
            <p>
              Everything is happy if you choose to make it that way. Van Dyke Brown is a very nice brown, it's almost
              like a chocolate brown. Let's get wild today. It's important to me that you're happy. You have to make
              those little noises or it won't work. Pretend you're water. Just floating without any effort. Having a
              good day. This piece of canvas is your world. A tree needs to be your friend if you're going to paint him.
              We'll lay all these little funky little things in there. Of course he's a happy little stone, cause we
              don't have any other kind. Trees get lonely too, so we'll give him a little friend. Even the worst thing
              we can do here is good.
            </p>
            <div className="btn-row">
              <Link to="/work">View Series</Link>
            </div>
          </div>
          <div className="images">
            <div className="top-right">
              <Img fluid={data.fist.childImageSharp.fluid} />
            </div>
            <div className="bottom-left">
              <Img fluid={data.flower.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </div>
      <div className="black-box"></div>
      <div className="black-box overlay"></div>
    </div>
  )
}

export default AboutBlurb